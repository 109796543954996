<template lang="pug">
  .period-settings
    table
      thead
        tr
          th.header-item.sticky-col.priod-group-name.sortable(@click="handleSorting('name')")
            span {{ $t("plan_management.base_rate_period_columns.group") }}
            FaIcon.icon(:icon="sortingIcon('name')")
          th.header-item.sticky-col.period-start-date
            span {{ $t("plan_management.base_rate_period_columns.start_date") }}
          th.header-item.sticky-col.period-end-date
            span {{ $t("plan_management.base_rate_period_columns.end_date") }}
          th.header-item.sticky-col.actions
      tbody
        template(v-for="periodSettingItemStore in [itemsAdded, items]")
          template(v-for="(periodSettingItem, index) in periodSettingItemStore.$each.$iter")
            tr(:class="{ 'updated': periodSettingItem._addedAt.$model || periodSettingItem._updated.$model }")
              td.name-cell(
                :key="periodSettingItem.id.$model || periodSettingItem._addedAt.$model"
                :rowspan="getItemRowspan(periodSettingItem)"
              )
                AppInput.name-input(
                  type="text"
                  :class="{ invalid: periodSettingItem.name.$error }"
                  :value="periodSettingItem.name.$model"
                  @input="handleNameInput(index, periodSettingItem, $event)"
                )
            template(v-for="(period, periodIndex) in periodSettingItem.periods.$each.$iter")
              PeriodItemRow(
                :index="parseInt(periodIndex)"
                :updated="!!(periodSettingItem._addedAt.$model || period._addedAt.$model || periodSettingItem._updated.$model)"
                :period="period"
                @update-period="updatePeriod(index, periodSettingItem, $event)"
                @remove-period="removePeriod(index, periodSettingItem, $event)"
              )
            tr.add-new-row(:class="{ 'updated': periodSettingItem._addedAt.$model || periodSettingItem._updated.$model }")
              td
                AppIconButton.add-new(
                  icon="plus-circle"
                  title="plan_management.actions.add_new",
                  @click="addNewPeriod(index, periodSettingItem)"
                )
              td
              td
</template>

<script>
  // mixins
  import withSorting from "@/mixins/withSorting"

  export default {
    components: {
      AppInput: () => import("@/components/elements/AppInput"),
      PeriodItemRow: () => import("./PeriodItemRow"),
      AppDatepicker: () => import("@/components/elements/AppDatepicker"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    mixins: [withSorting],

    props: {
      items: {
        type: Object,
        default: () => new Object()
      },
      itemsAdded: {
        type: Object,
        default: () => new Object()
      },
      sortingData: Object
    },

    methods: {
      getItemRowspan(item) {
        return item.$model.periods?.length + 2
      },

      handleNameInput(index, item, name) {
        this.updateItem({ ...item.$model, name }, parseInt(index))
      },

      updatePeriod(index, item, { periodIndex, period }) {
        const periods = item.periods.$model
        periods.splice(periodIndex, 1, period)
        this.updateItem({ ...item.$model, periods }, parseInt(index))
      },

      removePeriod(index, item, periodIndex) {
        const periods = item.periods.$model
        periods.splice(periodIndex, 1)
        this.updateItem({ ...item.$model, periods }, parseInt(index))
      },

      addNewPeriod(index, item) {
        const periods = [...item.periods.$model, { start: null, end: null, _addedAt: Date.now() }]
        this.updateItem({ ...item.$model, periods }, parseInt(index))
      },

      updateItem(item, index) {
        this.$emit("update-item", { index, item })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"
  @import "@/assets/styles/tables/custom.sass"
  @import "@/assets/styles/matchings.sass"

  .period-settings
    +listing-container(10px)
    position: relative
    overflow: auto
    margin-top: 20px

    table
      width: 100%
      table-layout: fixed
      overflow-wrap: break-word

      thead
        th
          +custom-table-th

          &.sticky-col
            +sticky-col
            top: 0
            z-index: 10

          &.header-item
            align-items: center
            span
              width: 260px

          &.priod-group-name
            width: 48%

          &.period-start-date,
          &.period-end-date
            width: 25%

          &.actions
            width: 2%
      tbody
        tr
          &.updated
            background-color: $default-purple-light

          &.add-new-row
            border-bottom: 1px solid $default-gray-light

          td
            padding: 5px 10px

            &.name-cell
              vertical-align: top

            .name-input
              width: 100%

            .name-input
              padding-left: 10px
              font-size: 0.8rem

            &.actions
              padding: 0
              color: $default-purple

              svg
                cursor: pointer
            .add-new
              background-color: $default-purple
              ::v-deep
                svg
                  path
                    fill: $default-white
                .title
                  color: $default-white

            ::v-deep
              input
                border-color: $default-purple !important
                height: 32px !important
</style>
